import React, { useEffect, useRef, useState } from "react"

import Video from "../components/Video"
import Cutout from "../components/Cutout"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"
import { useWindowSize } from "../hooks/useWindowSize"
import { useMousePosition } from "../hooks/useMousePosition"

import d1 from "../images/diamonds/d1.png"
import d2 from "../images/diamonds/d2.png"
import d3 from "../images/diamonds/d3.png"
import d4 from "../images/diamonds/d4.png"
import d5 from "../images/diamonds/d5.png"
import d6 from "../images/diamonds/d6.png"
import d7 from "../images/diamonds/d7.png"
import d8 from "../images/diamonds/d8.png"
import d9 from "../images/diamonds/d9.png"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Header = () => {

    if (typeof window !== "undefined") {
        var cx = window.innerWidth / 2;
        var cy = window.innerHeight / 2;
    }

    const position = useMousePosition()
    const size = useWindowSize()

    const [loaded, setLoaded] = useState(false)
    const [show, setShow] = useState(false)
    const [days, setDays] = useState('00')
    const [hours, setHours] = useState('00')
    const [mins, setMins] = useState('00')
    const [isComplete, setIsComplete] = useState(false)

    const d1Ref = useRef(null)
    const d2Ref = useRef(null)
    const d3Ref = useRef(null)
    const d4Ref = useRef(null)
    const d5Ref = useRef(null)
    const d6Ref = useRef(null)
    const d7Ref = useRef(null)
    const d8Ref = useRef(null)
    const d9Ref = useRef(null)

    const scrollRef = useRef(null)

    const toggleModal = e => {
        setShow(!show)
    }

    const close = async event => {
        setShow(false)
    }

    let isMobile = true

    if (size && size.width >= 1024) isMobile = false

    const Minting = () => {
        return !isComplete ? (<div className="h-32 sm:h-72 md:h-96 absolute bottom-16 lg:bottom-20 right-4 sm:right-0 flex items-center justify-end sm:justify-center lg:my-24">
            <div className="flex flex-col">
                <p className="text-white text-sm uppercase font-bold font-condensed tracking-wider">minting begins in:</p>
                <div className="flex flex-row justify-between uppercase text-xs font-condensed">
                    <div className="flex flex-col">
                        <p className="text-white text-5xl font-black font-mono">{days}:</p>
                        <p className="uppercase text-xs text-white font-condensed">days</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-white text-5xl font-black font-mono">{hours}:</p>
                        <p className="uppercase text-xs text-white font-condensed">hours</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-white text-5xl font-black font-mono">{mins}</p>
                        <p className="uppercase text-xs text-white font-condensed">mins</p>
                    </div>
                </div>
            </div>
        </div>)
            :
            (<div className="h-40 sm:h-48 absolute bottom-12 sm:bottom-24 lg:bottom-1 right-4 sm:right-0 flex items-center sm:items-end  justify-center lg:my-24 z-40">
                <div className="flex flex-col">
                    <p className="text-white text-sm uppercase font-bold font-condensed tracking-wider text-right">sold out in 1 hour on 11.17.21</p>
                    <div className="flex flex-col justify-between gap-y-10 uppercase text-xs text-gray font-condensed">
                        <p className="text-blue text-center mt-2 text-4xl font-mono">7000 / 7000</p>
                        <div className="hidden sm:flex relative w-full sm:w-auto rounded-md shadow ml-3">
                            <a href="https://opensea.io" target="_blank" rel="noreferrer" className="hvr-sweep-to-bottom hvr-sweep-to-bottom__white transition duration-700 ease-in-out text-center w-full flex items-center justify-center px-4 py-4 sm:px-8 sm:py-3 uppercase border-2 border-gray-light text-base font-medium font-condensed rounded-md text-white bg-black bg-opacity-50 md:py-4 md:text-lg md:px-10">
                                Buy on opensea
                            </a>
                        </div>
                    </div>
                </div>
            </div>)
    }

    const updateTime = () => {
        let now = new Date().getTime() / 1000;
        let endDate = 1637881200;

        let distanceEnd = endDate - now;

        let dEnd = Math.floor(distanceEnd / (3600 * 24));
        let hEnd = Math.floor((distanceEnd % (3600 * 24)) / 3600);
        let mEnd = Math.floor((distanceEnd % 3600) / 60);

        if (dEnd + hEnd + mEnd <= 0) {
            setIsComplete(true)
        } else {
            dEnd = dEnd < 10 ? `0${dEnd}` : dEnd
            hEnd = hEnd < 10 ? `0${hEnd}` : hEnd
            mEnd = mEnd < 10 ? `0${mEnd}` : mEnd

            setDays(dEnd)
            setHours(hEnd)
            setMins(mEnd)
        }
    }

    useEffect(() => {
        // updateTime()

        // if (!isComplete) {
        //     const timer1 = setInterval(function () {
        //         updateTime()
        //     }, 10000);

        //     return () => {
        //         clearTimeout(timer1);
        //     };
        // }

    }, [isComplete])

    useEffect(() => {
        let dx = position.x - cx;
        let dy = position.y - cy;

        let tiltx = (dy / cy);
        let tilty = -(dx / cx);

        // let radius = Math.sqrt(Math.pow(tiltx, 2) + Math.pow(tilty, 2));
        // let degree = (radius * 20);

        // gsap.to(".cutout", {
        //   transform: 'rotate3d(' + tiltx + ', ' + tilty + ', 0, ' + degree + 'deg)',
        //   ease: 'power2.out'
        // });

        gsap.to('.bg-header', {
            x: tilty * 5,
            y: -tiltx * 5
        });

        gsap.to('.header_stars', {
            x: tilty * 5,
            y: tiltx * 5
        });

        if (!loaded) {
            let timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: ".scrollPoint",
                    start: "top top",
                    scrub: 1,
                }
            })

            timeline.fromTo('.shards', {
                opacity: 0,
            }, {
                duration: 1,
                opacity: 1,
            });

            gsap.to(d1Ref.current, {
                yPercent: -30,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            gsap.to(d2Ref.current, {
                yPercent: -45,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            gsap.to(d3Ref.current, {
                yPercent: -55,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            gsap.to(d4Ref.current, {
                yPercent: -60,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            gsap.to(d5Ref.current, {
                yPercent: -45,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            gsap.to(d6Ref.current, {
                yPercent: -50,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            gsap.to(d7Ref.current, {
                yPercent: -60,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            gsap.to(d8Ref.current, {
                yPercent: -55,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            gsap.to(d9Ref.current, {
                yPercent: -70,
                ease: "none",
                scrollTrigger: {
                    trigger: ".scrollPoint2",
                    scrub: true,
                },
            })

            return () => {
                setLoaded(true)
            };
        }

        setLoaded(true)

    }, [loaded, position, cx, cy])

    return (
        <div className="navScroll relative w-screen h-full bg-black">
            {show ? <Video close={close} /> : null}
            <div className="relative h-full px-2 sm:px-6 z-0">
                <div className="bg-header absolute -left-20 -right-20 -bottom-20 -top-20 overflow-hidden"></div>
                <div className="header_stars absolute inset-0 overflow-hidden"></div>
                <div className=" relative w-full h-auto sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:flex lg:items-center lg:justify-center lg:my-32 z-10">
                    <div className="cutout flex mx-auto w-48 sm:w-96 sm:h-96 mt-10 sm:mt-1">
                        {!isMobile ? <Cutout /> : null}
                        <div className="lg:hidden">
                            <StaticImage
                                src="../images/cutout/fulltooth.png"
                                quality={100}
                                placeholder="tracedSVG"
                                loading="lazy"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="Tooth"
                            />
                        </div>
                    </div>
                </div>

                <div className="relative mx-auto max-w-7xl w-full -mt-16 sm:mt-0 sm:pb-24 lg:pt-40 text-left z-20 sm:z-0">
                    <div className="px-4 lg:w-1/2 sm:px-0 xl:pr-16">
                        <h1 className="leading-tighter tracking-wide font-normal text-white text-5xl sm:text-7xl uppercase ">
                            <span className="block">We want</span>
                            <span className="block">to build</span>
                            <span className="block">a better</span>
                            <span className="block">world.</span>
                        </h1>
                        <p className="mt-3 max-w-sm text-lg text-white md:mt-5 md:max-w-3xl font-normal leading-tight">
                            <span className="hidden sm:block">32% of all project proceeds will be used to implement</span>
                            <span className="hidden sm:block">projects to fix issues our community is passionate about</span>
                            <span className="block sm:hidden">32% of all project proceeds will be used to implement projects to fix issues our community is passionate about</span>
                        </p>
                        <div className="relative mt-32 sm:mt-10 flex justify-between sm:justify-start items-center gap-3 z-40">
                            {!isComplete ?
                                <div className="w-full sm:w-auto rounded-md shadow">
                                    <a href="#mint"
                                        className="hvr-sweep-to-bottom hvr-sweep-to-bottom__blue button button__right arrow transition duration-700 ease-in-out w-full flex items-center border border-white justify-center uppercase px-4 py-4 sm:py-3 text-base font-bold font-condensed rounded-md text-black bg-white md:py-4 md:text-lg md:px-12">
                                        <span>Mint an E32</span>
                                    </a>
                                </div>
                                : null}
                            <div className="w-full sm:w-auto rounded-md shadow">
                                {isComplete ?
                                    <div className="flex flex-row gap-x-1">
                                        <a href="https://discord.gg/envision32" target="_blank" rel="noreferrer" className="flex-shrink col-span-1 hvr-sweep-to-bottom hvr-sweep-to-bottom__white button button__right arrowright transition duration-700 ease-in-out w-full flex items-center justify-center px-4 py-4 sm:px-8 sm:py-3 uppercase border-2 border-gray-light black bg-opacity-50 text-base font-medium font-condensed rounded-md text-white  md:py-4 md:text-lg md:px-16">
                                            Join the Discord
                                        </a>
                                        <a href="https://opensea.io" target="_blank" rel="noreferrer" className="col-span-1 hvr-sweep-to-bottom hvr-sweep-to-bottom__white button button__right arrowright transition duration-700 ease-in-out w-full flex sm:hidden items-center justify-center px-4 py-4 sm:px-8 sm:py-3 uppercase border-2 border-gray-light text-base font-medium font-condensed rounded-md text-white bg-black bg-opacity-50 md:py-4 md:text-lg md:px-16">
                                            Buy on opensea
                                        </a>
                                    </div>
                                    :
                                    <a href="https://discord.gg/envision32" target="_blank" rel="noreferrer" className="hvr-sweep-to-bottom hvr-sweep-to-bottom__white transition duration-700 ease-in-out  w-full flex items-center justify-center px-4 py-4 sm:px-8 sm:py-3 uppercase border-2 border-gray-light text-base font-medium font-condensed rounded-md text-white bg-black bg-opacity-50 md:py-4 md:text-lg md:px-10 text-center">
                                        Join Discord
                                    </a>
                                }
                            </div>
                        </div>
                    </div>

                    <Minting />

                </div>
            </div>
            <div className="relative w-full h-96 flex items-center justify-center text-center mt-12 sm:-mt-0 lg:py-24 lg:text-left z-40">
                <div className=" sm:flex sm:justify-center lg:justify-center lg:items-center relative">

                    <video
                        className="video-player"
                        height="100%"
                        width="100%"
                        loop
                        muted
                        autoPlay
                    >
                        <source
                            src="https://vz-56aa1bfd-597.b-cdn.net/43597a43-7d64-4419-9770-557a17b01236/play_360p.mp4"
                            type="video/mp4"
                        />

                    </video>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30">
                        <button onClick={e => toggleModal(e)} className="filter drop-shadow-2xl hvr-sweep-to-bottom hvr-sweep-to-bottom__black button button__max button__left play min-w-max w-full text-right flex items-center justify-center uppercase px-8 py-3 border border-transparent group hover:bg-black hover:text-white text-base font-medium rounded-md text-black bg-white  md:py-4 md:text-lg md:px-12 shadow-2xl">
                            <span id="breakPoint" className="font-condensed font-bold">Watch intro</span>
                        </button>
                    </div>
                    {/* <div className="rounded-md filter drop-shadow-2xl">
                    <button onClick={e => toggleModal(e)} className="hvr-sweep-to-bottom hvr-sweep-to-bottom__black button button__max button__left play min-w-max w-full text-right flex items-center justify-center uppercase px-8 py-3 border border-transparent group hover:bg-black hover:text-white text-base font-medium rounded-md text-black bg-white  md:py-4 md:text-lg md:px-12 shadow-2xl">
                        <span id="breakPoint" className="font-condensed font-bold">Watch intro</span>
                    </button>
                    </div> */}
                </div>
            </div>

            <div id="overview"></div>


            <div name="overview" className="overview_stars max-w-4xl mx-auto py-16 px-2 sm:py-24 sm:px-6 lg:px-32">
                <div className="text-center px-4">
                    <p className="mt-1 font-extrabold font-condensed text-white tracking-wide uppercase text-4xl sm:text-5xl">
                        <span className="hidden sm:block">we are on a mission</span>
                        <span className="block sm:hidden">we are on</span>
                        <span className="block sm:hidden">a mission</span>

                    </p>
                    <p className=" max-w-xl mt-5 mx-auto text-xl text-gray-light sm:text-gray leading-tight">A set of 32 teeth can signify good oral health. This will be our initial launch, but we want that number 32 to be a brand to promote helping with issues around the world. Envision32 has created these teeth with the hope that they are valued not only as great art pieces, but also as tickets to collaborate to do good things. 32% of our proceeds will go into our project fund, which will be used towards initiatives that will be managed by us but driven by our community.</p>
                    <p ref={scrollRef} className="scrollPoint2 max-w-xl mt-5 mx-auto text-xl text-gray-light sm:text-gray leading-tight">For example, let’s say the community decides we should do a medical mission trip. We would organize and execute an event to support this ambition. Various projects tackling different issues will be implemented every few months.</p>
                </div>
            </div>

            <img src={d1} alt="" ref={d1Ref} className="hidden sm:block shards opacity-0 absolute right-0 top-1/2 z-40" />
            <img src={d2} alt="" ref={d2Ref} className="hidden sm:block shards opacity-0 absolute right-1/3 top-1/3 z-30" />
            <img src={d3} alt="" ref={d3Ref} className="hidden lg:block shards opacity-0 absolute right-1/2 top-1/3 z-30" />
            <img src={d4} alt="" ref={d4Ref} className="hidden lg:block shards opacity-0 absolute right-12 top-1/3 z-30" />
            <img src={d5} alt="" ref={d5Ref} className="hidden sm:block shards opacity-0 absolute left-20 top-2/3 z-30" />
            <img src={d6} alt="" ref={d6Ref} className="hidden sm:block shards opacity-0 absolute -right-2 top-3/4 z-30" />
            <img src={d7} alt="" ref={d7Ref} className="hidden lg:block shards opacity-0 absolute left-40 top-3/4 z-30" />
            <img src={d8} alt="" ref={d8Ref} className="hidden sm:block shards opacity-0 absolute right-1/3 top-full z-30" />
            <img src={d9} alt="" ref={d9Ref} className="hidden sm:block shards opacity-0 absolute right-0 top-full z-30" />
        </div>
    )
}

export default Header
