import React from "react"
import { Link } from "gatsby"

const invalidPaths = ["/", "",]

const isActive = (className, destination) => ({ location }) => {
  const activeClassName = { className: `${className} is-active` }

  if (!invalidPaths.includes(location.hash)) {
      if (location.hash === destination) {
        return activeClassName
      } else {
        return { className }
      }
  } else {
    return { className }
  }

}

const NavLink = ({ className, destination, children, ...props }) => (
  <Link getProps={isActive(className, destination)} {...props}>
    {children}
  </Link>
)

export default NavLink
