import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby";
import Nav from '../components/Nav'
import Header from '../components/Header'
import Slider from '../components/Slider'
import Collection from "../components/Collection"
import Team from "../components/Team"
import Mission from "../components/Mission"
import Cast from "../components/Cast"
import Athletes from "../components/Athletes"
import Twitter from "../components/Twitter"
import Footer from "../components/Footer"

const IndexPage = ({
    data: {
        site,
        allMarkdownRemark: { edges },
    },
}) => {


    console.log(edges)
    // .map((edge) => <p key={edge.node.id} post={edge.node} >{edge.node}</p>);

    const data = edges[0].node.frontmatter


    return (
        <main className=" bg-black">
            <Helmet>
                <meta charSet="utf-8" />
                <title>E32</title>
            </Helmet>
            <title>E32</title>
            <Nav />
            <Header />

            <div id="teeth"></div>

            <div className="w-full flex justify-center">
                <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                    <defs>
                        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>

            {/* <Slider /> */}

            <Collection />

            <div id="team"></div>

            <div className="w-full flex justify-center">
                <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                    <defs>
                        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>

            <Team team={data.team} />

            <div id="mission"></div>

            <div className="w-full flex justify-center">
                <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                    <defs>
                        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>

            <Mission />

            <div className="w-full flex justify-center">
                <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                    <defs>
                        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>

            <div id="cast"></div>

            <Cast />



            <div className="w-full flex justify-center">
                <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                    <defs>
                        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>

            {/* <div id="athletes"></div>

      <Athletes /> */}



            {/* <div id="announcements"></div>

      <div className="w-full flex justify-center">
        <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474"/>
            <defs>
            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </radialGradient>
            </defs>
        </svg>
    </div>

      <Twitter tweets={data.twitter}/> */}

            <Footer />
        </main>
    )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            twitter {
                path
            }
            team {
                name
                role
                bio
                twitter
                image
            }
          }
        }
      }
    }
  }
`;
