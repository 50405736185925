import React, { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { StaticImage } from "gatsby-plugin-image"
import "swiper/swiper.min.css"

import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation } from "swiper/core"

import d10 from "../images/diamonds/d10.png"
import d11 from "../images/diamonds/d11.png"
import d12 from "../images/diamonds/d12.png"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

// install Swiper modules
SwiperCore.use([Autoplay, Navigation])

const Slider = () => {
    const swiperRef = useRef(null)
    const [swiper, setSwiper] = useState(null);
    console.log(swiper)

    const d10Ref = useRef(null)
    const d11Ref = useRef(null)
    const d12Ref = useRef(null)

    useEffect(() => {
        gsap.to(d10Ref.current, {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
              trigger: ".teeth",
              scrub: true,
            },
          })

          gsap.to(d11Ref.current, {
            yPercent: -45,
            ease: "none",
            scrollTrigger: {
                trigger: ".teeth",
                scrub: true,
            },
          })

          gsap.to(d12Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".teeth",
                scrub: true,
            },
          })
    }, [])

    return (
        <div className="bg-slider teeth pt-16 sm:pt-24 sm:pb-24 relative w-screen h-full bg-black">
            <div className="slider_stars">
                <div className="">
                    <div className="text-center -mb-8 sm:-mb-16">
                        <p className="mt-1 text-2xl font-extrabold font-condensed tracking-wider text-white uppercase sm:text-3xl lg:text-5xl">Meet the</p>
                        <p className="-mt-1 text-8xl font-extrabold font-condensed tracking-widest text-white uppercase sm:text-9xl lg:text-20xl">teeth</p>
                    </div>
                    <div className=" relative">
                    <Swiper
                    onSlideChange={(e) => {
                        setSwiper(e);}}
                  slidesPerView={1.4}
                  spaceBetween={10}
                  centeredSlides={true}
                  loop={true}
                  navigation={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.8,
                      spaceBetween: 80,
                    },
                    640: {
                      slidesPerView: 2.8,
                      spaceBetween: 100,
                    },
                    2000: {
                        slidesPerView: 3.8,
                        spaceBetween: 200
                    },
                  }}
                  ref={swiperRef}
                >
              <SwiperSlide>
                <StaticImage
                className="filter drop-shadow-2xl"
                  src="../images/swiper1.png"
                  quality={100}
                  height={380}
                  placeholder="tracedSVG"
                  loading="lazy"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Tooth"
                />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper2.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper3.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper4.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper5.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper6.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper7.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper8.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper9.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper10.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper11.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper12.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper13.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper14.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
                        <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper15.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper16.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper17.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper18.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper19.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper20.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper21.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper22.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper23.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper24.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper25.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper26.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper27.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper28.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper29.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper30.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper31.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
              className="filter drop-shadow-2xl"
                src="../images/swiper32.png"
                quality={100}
                height={380}
                placeholder="tracedSVG"
                loading="lazy"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Tooth"
              />

            </SwiperSlide>
        </Swiper>
        <div className="absolute left-0 right-0 top-1/3 flex flex-row justify-between items-center mx-4 mt-4 sm:hidden">
            <div
              id="previousButton__m"
              aria-label="Previous image"
              className="w-80 cursor-pointer shadow-sm focus:outline-none flex justify-start z-20"
              onClick={() => swiperRef.current.swiper.slidePrev()}
              onKeyDown={() => swiperRef.current.swiper.slidePrev()}
              role="button"
              tabIndex={0}
            >
                <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.15" d="M1 37C1 17.1177 17.1178 0.999995 37 0.999997C56.8823 0.999999 73 17.1177 73 37C73 56.8823 56.8823 73 37 73C17.1177 73 1 56.8822 1 37Z" stroke="white" strokeWidth="2"/>
                    <path d="M24.9393 35.9393C24.3536 36.5251 24.3536 37.4749 24.9393 38.0607L34.4853 47.6066C35.0711 48.1924 36.0208 48.1924 36.6066 47.6066C37.1924 47.0208 37.1924 46.0711 36.6066 45.4853L28.1213 37L36.6066 28.5147C37.1924 27.9289 37.1924 26.9792 36.6066 26.3934C36.0208 25.8076 35.0711 25.8076 34.4853 26.3934L24.9393 35.9393ZM48 35.5L26 35.5L26 38.5L48 38.5L48 35.5Z" fill="white"/>
                </svg>
            </div>

            <div
              id="nextButton__m"
              aria-label="Next image"
              className="w-80 flex justify-end cursor-pointer shadow-sm focus:outline-none z-20"
              onClick={() => swiperRef.current.swiper.slideNext()}
              onKeyDown={() => swiperRef.current.swiper.slideNext()}
              role="button"
              tabIndex={0}
            >
                <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.15" d="M73 37C73 56.8823 56.8823 73 37 73C17.1177 73 1 56.8823 1 37C1 17.1177 17.1177 1 37 1C56.8823 1 73 17.1177 73 37Z" stroke="white" strokeWidth="2"/>
                    <path d="M49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM26 38.5H48V35.5H26V38.5Z" fill="white"/>
                </svg>
            </div>
        </div>
        <div className="hidden sm:flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 flex-row justify-between">
            <div
              id="previousButton"
              aria-label="Previous image"
              className="w-80 cursor-pointer shadow-sm focus:outline-none flex justify-start"
              onClick={() => swiperRef.current.swiper.slidePrev()}
              onKeyDown={() => swiperRef.current.swiper.slidePrev()}
              role="button"
              tabIndex={0}
            >
                <svg className="transform hover:scale-105" width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.15" d="M1 37C1 17.1177 17.1178 0.999995 37 0.999997C56.8823 0.999999 73 17.1177 73 37C73 56.8823 56.8823 73 37 73C17.1177 73 1 56.8822 1 37Z" stroke="white" strokeWidth="2"/>
                    <path d="M24.9393 35.9393C24.3536 36.5251 24.3536 37.4749 24.9393 38.0607L34.4853 47.6066C35.0711 48.1924 36.0208 48.1924 36.6066 47.6066C37.1924 47.0208 37.1924 46.0711 36.6066 45.4853L28.1213 37L36.6066 28.5147C37.1924 27.9289 37.1924 26.9792 36.6066 26.3934C36.0208 25.8076 35.0711 25.8076 34.4853 26.3934L24.9393 35.9393ZM48 35.5L26 35.5L26 38.5L48 38.5L48 35.5Z" fill="white"/>
                </svg>

            </div>
            <div
                    id="nextButton"
                    aria-label="Next image"
                    className="w-80 cursor-pointer shadow-sm focus:outline-none flex justify-end "
                    onClick={() => swiperRef.current.swiper.slideNext()}
                    onKeyDown={() => swiperRef.current.swiper.slideNext()}
                    role="button"
                    tabIndex={0}
                  >
                  <svg className="transform hover:scale-105" width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.15" d="M73 37C73 56.8823 56.8823 73 37 73C17.1177 73 1 56.8823 1 37C1 17.1177 17.1177 1 37 1C56.8823 1 73 17.1177 73 37Z" stroke="white" strokeWidth="2"/>
<path d="M49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM26 38.5H48V35.5H26V38.5Z" fill="white"/>
</svg>

                  </div>
            </div>
            </div>
          </div>

          <div id="collection"></div>
            <img src={d10} alt="" ref={d10Ref} className="hidden sm:block shards opacity-0 absolute left-0 lg:left-40 -top-20 z-40" />
            <img src={d11} alt="" ref={d11Ref} className="hidden lg:block shards opacity-0 absolute right-1/4 top-40 z-40" />
            <img src={d12} alt="" ref={d12Ref} className="hidden lg:block shards opacity-0 absolute left-1/4 top-3/4 z-40" />
            </div>
        </div>
    )
}

export default Slider
