import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

import d23 from "../images/diamonds/d23.png"
import d24 from "../images/diamonds/d24.png"
import d25 from "../images/diamonds/d25.png"
import d26 from "../images/diamonds/d26.png"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Athletes = () => {

    const d23Ref = useRef(null)
    const d24Ref = useRef(null)
    const d25Ref = useRef(null)
    const d26Ref = useRef(null)

    useEffect(() => {

        gsap.to(d23Ref.current, {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
                trigger: ".mission",
                scrub: true,
            },
        })

        gsap.to(d24Ref.current, {
            yPercent: -45,
            ease: "none",
            scrollTrigger: {
                trigger: ".mission",
                scrub: true,
            },
        })

        gsap.to(d25Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".mission",
                scrub: true,
            },
        })


        gsap.to(d26Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".mission",
                scrub: true,
            },
        })

    }, [])

    return (
        <div className="mission mission_stars relative w-screen h-full bg-black">
            <div className="max-w-2xl mx-auto py-12 px-2 sm:py-24 sm:px-0">
                <h1 className="text-white text-center text-4xl sm:text-5xl font-extrabold font-condensed tracking-wide uppercase">
                    <span className="block">E32</span>
                    <span className="block">Athletes</span>
                </h1>
                <p className="max-w-2xl mx-auto text-center text-lg font-normal text-gray-light sm:text-gray mt-3">Being an excellent full-time student is already hard enough on its own. Adding the demanding schedule of being an athlete makes it difficult to excel both in academics and in sports. We will be sponsoring college athletes across all sports and hope to support them in their endeavors.</p>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-24 mt-12">

                    <div className="col-span-1">
                        <div className="h-full">
                            <a href="https://twitter.com/BruenerCarson" target="_blank" rel="noreferrer" className="space-y-2 flex flex-col justify-between h-full">
                                <StaticImage
                                    className="rounded-md w-full object-contain"
                                    src="../images/athletes/1.png"
                                    quality={100}

                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="athlete"
                                />
                                <div className="text-lg leading-6 text-white font-medium ">
                                    <h3 className="font-condensed text-2xl uppercase">Carson Bruener</h3>
                                    <p className="text-white text-base">University of Washington</p>
                                </div>
                                <div className="text-base">
                                    <p className="text-gray">Carson is studying Communications and wants to pursue a career in Real Estate. He loves spending time with his friends and family.</p>
                                </div>
                                <div className="text-white group flex flex-row gap-2 mt-4">
                                    <span className="sr-only">Twitter</span>
                                    <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                    </svg>

                                    <span>@bruenercarson</span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <div className="h-full">
                            <a href="https://twitter.com/KikiMilloy" target="_blank" rel="noreferrer" className="space-y-2 flex flex-col justify-between h-full">
                                <StaticImage
                                    className="rounded-md object-contain"

                                    src="../images/athletes/2.jpg"
                                    quality={100}

                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="athlete"
                                />
                                <div className="text-lg leading-6 text-white font-medium ">
                                    <h3 className="font-condensed text-2xl uppercase">Kiki Milloy</h3>
                                    <p className="text-white text-base">University of Tennessee</p>
                                </div>
                                <div className="text-base">
                                    <p className="text-gray">Kiki is studying Neuroscience and plans to earn an MBA after undergrad. In her free time, she enjoys being active and going on hikes.</p>
                                </div>
                                <div className="text-white group flex flex-row gap-2 mt-4">
                                    <span className="sr-only">Twitter</span>
                                    <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                    </svg>

                                    <span>@kikimilloy</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>


            <img src={d23} alt="" ref={d23Ref} className="hidden lg:block shards opacity-0 absolute right-40 top-20 z-40" />
            <img src={d24} alt="" ref={d24Ref} className="hidden sm:block shards opacity-0 absolute -left-4 top-full z-40" />
            <img src={d25} alt="" ref={d25Ref} className="hidden sm:block shards opacity-0 absolute left-1/4 top-full z-40" />
            <img src={d26} alt="" ref={d26Ref} className="hidden sm:block shards opacity-0 absolute right-40 top-full z-40" />
        </div>
    )
}

export default Athletes
