import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

import d13 from "../images/diamonds/d13.png"
import d14 from "../images/diamonds/d14.png"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Collection = () => {

    const d13Ref = useRef(null)
    const d14Ref = useRef(null)

    useEffect(() => {
        gsap.to(d13Ref.current, {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
                trigger: ".collection",
                scrub: true,
            },
        })

        gsap.to(d14Ref.current, {
            yPercent: -80,
            ease: "none",
            scrollTrigger: {
                trigger: ".collection",
                scrub: true,
            },
        })
    }, [])

    return (
        <div className="collection collection_stars relative w-screen h-full bg-transparent">
            <div className="lg:max-w-7xl mx-4 sm:mx-6 xl:mx-auto py-16 px-2 sm:py-24 sm:px-0">
                <div className="row-span-2 col-span-4 text-center flex sm:hidden flex-col justify-evenly lg:px-16">
                    <h1 className="text-white text-4xl sm:text-5xl font-extrabold font-condensed tracking-wide uppercase">
                        <span className="block">E32</span>
                        <span className="block">Collection</span>
                    </h1>
                    <p className="max-w-xl mx-auto text-lg font-normal text-gray-light sm:text-gray mt-3">These 32 nongenerative 1 of 1s will be used to expand our reach. The IP for these 32 will remain with <a className="text-white underline cursor-pointer" href='https://Envision32.com'>Envision32</a>.</p>
                    <p className="max-w-xl mx-auto text-lg font-normal text-gray-light sm:text-gray mt-3">
                        Interested in working with us?
                        <a href="mailto:drcape@envision32.com">
                            <span className="block w-28 mx-auto text-center text-white underline cursor-pointer"> Contact us</span>
                        </a>
                    </p>
                </div>
                <div className="grid grid-cols-4 sm:grid-cols-8 gap-4 auto-rows-max mt-8 sm:mt-0">
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar1.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar5.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar12.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/premolar6.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/premolar3.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/canine3.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/incisor4.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/incisor7.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/incisor2.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/incisor1.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="row-span-2 col-span-4 text-center hidden sm:flex flex-col justify-evenly lg:px-16">
                        <h1 className="text-white text-xl sm:text-3xl lg:text-6xl font-condensed tracking-wide uppercase">
                            <span className="hidden sm:block">E32</span>
                            <span className="hidden sm:block">Collection</span>
                            <span className="block sm:hidden">E32 Collection</span>
                        </h1>
                        <p className="max-w-xl mx-auto text-lg font-normal text-gray leading-tight">These 32 nongenerative 1 of 1s will be used to expand our reach. The IP for these 32 will remain with <a className="text-white underline cursor-pointer" href='https://Envision32.com'>Envision32</a>.</p>
                        <p className="max-w-xl mx-auto text-lg font-normal text-gray leading-tight"><a href="mailto:drcape@envision32.com">Interested in working with us?<span className="text-white underline cursor-pointer"> Contact us</span></a></p>
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/canine4.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/premolar2.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/premolar1.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar3.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar7.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar4.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar9.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar11.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar6.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/premolar4.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/premolar5.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/canine2.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/incisor3.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/incisor5.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/incisor6.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/incisor8.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/canine1.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/premolar7.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/premolar8.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar8.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar10.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/teeth/molar2.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Tooth"
                        />
                    </div>
                </div>
            </div>

            <img src={d13} alt="" ref={d13Ref} className="hidden lg:block shards opacity-0 absolute -left-4 -top-4 z-40" />
            <img src={d14} alt="" ref={d14Ref} className="hidden sm:block shards opacity-0 absolute right-4 -top-12 lg:-top-4 z-40" />
        </div>
    )
}

export default Collection
