import React, { useEffect } from "react"
const Video = ({ close }) => {

    const closeModal = async event => {
        document.body.classList.remove("overflow-y-hidden")
        close()
    }

    useEffect(() => {
        document.body.classList.add("overflow-y-hidden")
    }, [])

    return (
        <div>
            <div
              className="fixed z-50 inset-0 overflow-y-auto"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
                <div className="flex items-center justify-center min-h-screen sm:pt-4 sm:pb-20 text-center sm:block sm:p-0">
                    <div
                      className='fixed inset-0 backdrop-filter backdrop-blur-lg bg-black bg-opacity-80'
                      aria-hidden="true"
                      onClick={() => closeModal()}
                    ></div>

                    <span
                      className="inline-block align-middle h-screen"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>

                    <div
                      className='inline-block sm:px-8 overflow-hidden align-middle max-w-7xl w-full'
                    >
                        <div className="relative" style={{padding:'56.25% 0 0 0'}}>
                            <iframe style={{position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}} src="https://www.youtube.com/embed/Ujjn7pn_tA4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>

                    <header className="block absolute top-0 w-full z-50">
                      <nav className="mx-4 sm:mx-8 lg:mx-20 pb-6" aria-label="Top">
                        <div className=" pt-6 flex items-center justify-end">
                            <div className="  flex items-center justify-between gap-1">
                            <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="8.63477" y1="20.5062" x2="21.3627" y2="7.77827" stroke="#AFAEAE" stroke-width="2" stroke-linecap="round"/>
                            <line x1="21.3612" y1="20.5059" x2="8.63326" y2="7.77794" stroke="#AFAEAE" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                          <button
                            onClick={() => closeModal()}
                            className="block py-4  text-center text-base font-bold text-white cursor-pointer"
                          >
                            CLOSE
                          </button>
                          </div>
                        </div>
                      </nav>
                    </header>

                </div>
            </div>
        </div>
    )
}

export default Video
