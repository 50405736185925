import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

import { Swiper, SwiperSlide } from "swiper/react"
import { StaticImage } from "gatsby-plugin-image"
import "swiper/swiper.min.css"

import { useWindowSize } from "../hooks/useWindowSize"
// import Confetti from 'react-confetti'

// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper/core"

import d29 from "../images/diamonds/d29.png"
import d30 from "../images/diamonds/d30.png"
import d31 from "../images/diamonds/d31.png"

SwiperCore.use([Autoplay])

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Footer = () => {
    const swiperRef = useRef(null)
    const [days, setDays] = useState('00')
    const [hours, setHours] = useState('00')
    const [mins, setMins] = useState('00')
    const [isComplete, setIsComplete] = useState(false)
    const [width, setWidth] = useState(null)
    const [height, setHeight] = useState(null)
    // const [runConfetti, setRunConfetti] = useState(false)

    const d29Ref = useRef(null)
    const d30Ref = useRef(null)
    const d31Ref = useRef(null)

    const size = useWindowSize()

    const mint = () => {
        // setRunConfetti(true)
    }

    const updateTime = () => {
        let now = new Date().getTime() / 1000;
        let endDate = 1637881200;

        let distanceEnd = endDate - now;

        let dEnd = Math.floor(distanceEnd / (3600 * 24));
        let hEnd = Math.floor((distanceEnd % (3600 * 24)) / 3600);
        let mEnd = Math.floor((distanceEnd % 3600) / 60);

        if (dEnd + hEnd + mEnd <= 0) {
            setIsComplete(true)
        } else {
            dEnd = dEnd < 10 ? `0${dEnd}` : dEnd
            hEnd = hEnd < 10 ? `0${hEnd}` : hEnd
            mEnd = mEnd < 10 ? `0${mEnd}` : mEnd

            setDays(dEnd)
            setHours(hEnd)
            setMins(mEnd)
        }
    }

    useEffect(() => {

        setWidth(size.width)
        setHeight(size.height)

        // updateTime()

        gsap.to(d29Ref.current, {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
                trigger: ".minttrigger",
                scrub: true,
            },
        })
        gsap.to(d30Ref.current, {
            yPercent: -45,
            ease: "none",
            scrollTrigger: {
                trigger: ".minttrigger",
                scrub: true,
            },
        })
        gsap.to(d31Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".minttrigger",
                scrub: true,
            },
        })

        // if (!isComplete) {
        //     const timer1 = setInterval(function () {
        //         updateTime()
        //     }, 10000);

        //     return () => {
        //         clearTimeout(timer1);
        //     };
        // }

    }, [isComplete, size])

    const Minting = () => {
        return !isComplete ? (
            <div className="bg-footer">
                <div className="w-full flex justify-center ">
                    <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                        <defs>
                            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="white" stopOpacity="0" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
                <div className=" max-w-7xl mx-auto py-16 px-6 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <p className="mt-1 font-extrabold font-condensed tracking-wider text-white uppercase text-4xl sm:text-5xl">3,200 collectibles for sale </p>
                        <p className="text-gray-light text-xl leading-tight mt-2">
                            <span className="hidden sm:block">320 teeth will be held for promotional purposes.</span>
                            <span className="hidden sm:block">None will go to team members. Team members will (and better</span>
                            <span className="hidden sm:block">if they want to stay on the team) mint with the general public.</span>

                            <span className="block sm:hidden">320 teeth will be held for promotional</span>
                            <span className="block sm:hidden">purposes. None will go to team</span>
                            <span className="block sm:hidden">members. Team members will (and</span>
                            <span className="block sm:hidden">better if they want to stay on the team)</span>
                            <span className="block sm:hidden">mint with the general public.</span>
                        </p>
                        {/* <p className="text-blue text-xl">Minting starts November 25, 2021 6PM ET.</p> */}
                        <p className="text-blue text-xl">Minting starts TBD.</p>
                    </div>

                    <div className="flex flex-col mt-12">
                        <div className="flex flex-row justify-center uppercase font-condensed">
                            <div className="flex flex-col">
                                <p className="text-white text-6xl sm:text-9xl lg:text-10xl font-black font-mono">{days}:</p>
                                <p className="uppercase text-base font-condensed text-center text-gray-light font-condensed">days</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-white text-6xl sm:text-9xl lg:text-10xl font-black font-mono">{hours}</p>
                                <p className="uppercase text-base font-condensed text-center text-gray-light font-condensed">hours</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-white text-6xl sm:text-9xl lg:text-10xl font-black font-mono">:{mins}</p>
                                <p className="uppercase text-base font-condensed text-center text-gray-light font-condensed">mins</p>
                            </div>

                        </div>
                        <p className="text-blue text-center my-4 text-2xl sm:text-4xl font-mono">0000 / 3,200</p>
                        <div className="max-w-md mx-auto">
                            {/* <p className="text-gray text-center mt-2 text-lg">
                                <span className="block sm:hidden">Enter the amount of teeth</span>
                                <span className="block sm:hidden">you’d like to purchase.</span>
                                <span className="hidden sm:block">Enter the amount of teeth you’d like to purchase.</span>
                                <span className="block">Price per tooth is <span className="text-blue">0.07 ETH</span> each.</span>
                            </p> */}
                            <form className="flex flex-col space-y-3 mt-3">
                                <label htmlFor="mint" className="sr-only">mint</label>
                                {/* <div className="w-full relative">
                                    <input name="mint" className="w-full border border-blue rounded-md h-12 text-center font-mono text-white text-3xl bg-transparent" defaultValue="0" />
                                    <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                                        <span className="text-gray text-sm">10 max</span>
                                    </div>
                                </div> */}
                                <button className="hvr-sweep-to-bottom hvr-sweep-to-bottom__blue w-full uppercase px-4 h-12 border border-transparent text-base font-medium font-condensed rounded-md text-black bg-white md:text-lg md:px-8" type="button">MINT</button>
                            </form>
                            <a href="https://opensea.io" target="_blank" rel="noreferrer" className="flex flex-row gap-2 items-center justify-center mt-3">
                                <p className="text-white text-center font-black text-lg mt-2">
                                    View on Opensea</p>
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289L7.34315 0.928932C6.95262 0.538408 6.31946 0.538408 5.92893 0.928932C5.53841 1.31946 5.53841 1.95262 5.92893 2.34315L11.5858 8L5.92893 13.6569C5.53841 14.0474 5.53841 14.6805 5.92893 15.0711C6.31946 15.4616 6.95262 15.4616 7.34315 15.0711L13.7071 8.70711ZM0 9H13V7L0 7L0 9Z" fill="white" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className=" w-full z-50">
                    <Swiper
                        slidesPerView={8.2}
                        spaceBetween={0}
                        centeredSlides={true}
                        freeMode={true}
                        loop={true}
                        speed={15000}
                        freeModeMomentum={false}
                        autoplay={{
                            "delay": 1,
                            "disableOnInteraction": false
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 3.2,
                                spaceBetween: 10
                            },
                            640: {
                                slidesPerView: 8.2,
                                spaceBetween: 10
                            },
                            1440: {
                                slidesPerView: 16.2,
                                spaceBetween: 10
                            },
                        }}
                        ref={swiperRef}
                    >
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar1.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar5.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar12.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/premolar6.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/premolar3.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/canine3.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/incisor4.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/incisor7.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/incisor2.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/incisor1.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/canine4.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/premolar2.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/premolar1.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar3.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar7.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar4.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar9.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar11.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar6.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/premolar4.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/premolar5.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/canine2.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/incisor3.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/incisor5.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/incisor6.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/incisor8.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/canine1.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/premolar7.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/premolar8.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar8.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar10.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="bg-collection">
                                <StaticImage
                                    src="../images/teeth/molar2.png"
                                    quality={100}
                                    height={142}
                                    width={142}
                                    placeholder="blurred"
                                    loading="lazy"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Tooth"
                                />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:pt-16 sm:px-6 lg:px-8">

                    <div className="w-full flex justify-center">
                        <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                            <defs>
                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>

                    <div className="text-center mt-8">
                        <p className="mt-1 text-base text-gray">Copyright © 2023 E32. All rights reserved.</p>
                    </div>
                </div>


            </div>
        ) : (
            <div className="bg-footer-complete">
                <div className="w-full flex justify-center pt-16 sm:pt-24">
                    <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                        <defs>
                            <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="white" stopOpacity="0" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
                <div className=" max-w-7xl mx-auto py-16 px-6 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <p className="mt-1  font-extrabold font-condensed tracking-wider text-white uppercase text-4xl sm:text-5xl">join our community</p>
                        <p className="text-gray-light text-xl leading-tight mt-2">


                            <span className="hidden sm:block">Have any questions about the project? Join the discord for</span>
                            <span className="hidden sm:block">more information. Our Discord has over 10,000 members!</span>
                            <span className="hidden sm:block">If you want to join the #MEKAGANG it’s here.</span>

                            <span className="block sm:hidden">Have any questions about the project?</span>
                            <span className="block sm:hidden">Join the discord for more information.</span>
                            <span className="block sm:hidden">Our Discord has over 10,000 members!</span>
                            <span className="block sm:hidden">If you want to join the</span>
                            <span className="block sm:hidden">#MEKAGANG it’s here.</span>
                        </p>
                    </div>

                    <div className="flex flex-col mt-12">
                        <div className="max-w-md mx-auto">
                            <a href="https://discord.gg/envision32" target="_blank" rel="noreferrer" className="hvr-sweep-to-bottom hvr-sweep-to-bottom__blue button button__right arrowright transition duration-700 ease-in-out w-full flex items-center border border-white justify-center uppercase px-4 py-4 sm:py-3 text-base font-bold font-condensed rounded-md text-white md:py-4 md:text-lg md:px-16">join the e32 discord</a>
                        </div>
                    </div>
                </div>

                <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:pt-16 sm:px-6 lg:px-8">

                    <div className="w-full flex justify-center">
                        <svg width="1340" height="2" viewBox="0 0 1340 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line opacity="0.2" y1="1.41263" x2="1340" y2="1.41263" stroke="url(#paint0_radial)" strokeWidth="1.17474" />
                            <defs>
                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(670 2.50222) scale(524 702160)">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>

                    <div className="text-center mt-8">
                        <p className="mt-1 text-base text-gray-light">Copyright © 2023 E32. All rights reserved.</p>
                    </div>
                </div>

                {/* <img src={d29} alt="" ref={d29Ref} className="hidden sm:block shards opacity-0 absolute right-40 top-20 z-40" />
            <img src={d30} alt="" ref={d30Ref} className="hidden sm:block shards opacity-0 absolute -left-12 lg:-left-4 top-1/4 z-40" />
            <img src={d31} alt="" ref={d31Ref} className="hidden sm:block shards opacity-0 absolute right-12 top-2/4 z-40" /> */}
            </div>
        )
    }

    return (
        <div id="mint" className="minttrigger footer_stars relative w-screen h-full bg-black">
            <Minting />

            <img src={d29} alt="" ref={d29Ref} className="hidden sm:block shards opacity-0 absolute right-40 top-20 z-40" />
            <img src={d30} alt="" ref={d30Ref} className="hidden sm:block shards opacity-0 absolute -left-4 top-1/4 z-40" />
            <img src={d31} alt="" ref={d31Ref} className="hidden sm:block shards opacity-0 absolute right-12 top-2/4 z-40" />
        </div>
    )
}

export default Footer
