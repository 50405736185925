import React, { useEffect, useRef } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

import d23 from "../images/diamonds/d23.png"
import d24 from "../images/diamonds/d24.png"
import d25 from "../images/diamonds/d25.png"
import d26 from "../images/diamonds/d26.png"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Mission = () => {

    const d23Ref = useRef(null)
    const d24Ref = useRef(null)
    const d25Ref = useRef(null)
    const d26Ref = useRef(null)

    useEffect(() => {

        gsap.to(d23Ref.current, {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
                trigger: ".mission",
                scrub: true,
            },
        })

        gsap.to(d24Ref.current, {
            yPercent: -45,
            ease: "none",
            scrollTrigger: {
                trigger: ".mission",
                scrub: true,
            },
        })

        gsap.to(d25Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".mission",
                scrub: true,
            },
        })


        gsap.to(d26Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".mission",
                scrub: true,
            },
        })

    }, [])

    return (
        <div className="mission mission_stars relative w-screen h-full bg-black">
            <div className="lg:max-w-7xl mx-4 sm:mx-6 xl:mx-auto py-12 px-2 sm:py-24 sm:px-0">
                <p className="mt-1  font-extrabold font-condensed tracking-wider text-white uppercase  text-4xl sm:text-5xl text-center">current objectives</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-12">

                    <div className="col-span-1">
                        <div className="bg-black-light bg-opacity-10 p-8 sm:items-start h-full rounded-md">
                            <div className="space-y-4 flex flex-col justify-between h-full">
                                <div className="text-lg leading-6 text-white font-medium space-y-1">
                                    <h3 className="font-condensed text-2xl uppercase">1) organizing projects</h3>
                                    <p className="text-base text-gray">
                                        <span className="hidden sm:block">As a community, we will vote on real world</span>
                                        <span className="hidden sm:block">matters that we would like to see</span>
                                        <span className="hidden sm:block">improvements in. As a team, we will then </span>
                                        <span className="hidden sm:block">implement projects to address these issues.</span>
                                        <span className="hidden sm:block">After the completion of one project, another will be started.</span>
                                        <span className="block sm:hidden">As a community, we will vote on real world matters that we would like to see improvements in. As a team, we will then implement projects to address these issues. After the completion of one project, another will be started.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <div className="bg-black-light bg-opacity-10 p-8 sm:items-start h-full rounded-md">
                            <div className="space-y-4 flex flex-col justify-between h-full">
                                <div className="text-lg leading-6 text-white font-medium space-y-1">
                                    <h3 className="font-condensed text-2xl uppercase">2) media</h3>
                                    <p className="text-base text-gray">To supplement each of our events, our team will create different forms of media to bring to light the issues at hand and present how we are trying to make things better. Forms of media include cinematics, journalism, and photography. Our team will work with artists from around the world and across different disciplines. Teeth holders can potentially have their personal assets in these pieces and various produced pieces will be given to teeth holders.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <div className="bg-black-light bg-opacity-10 p-8 sm:items-start h-full rounded-md">
                            <div className="space-y-4 flex flex-col justify-between h-full">
                                <div className="text-lg leading-6 text-white font-medium space-y-1">
                                    <h3 className="font-condensed text-2xl uppercase">3) CREATING THE ENVISION32 UNIVERSE</h3>
                                    <p className="text-base text-gray">As outlined in E32 Cast, we will create an individual story for each of our 32 characters and they will come together in a series. While our stories will be fictional, each character will serve as a representative for a real-world issue. We are currently exploring the best way to provide ownership over these characters to teeth holders. Teeth holders can potentially have their personal assets featured in these stories. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-span-1">
                        <div className="bg-black-light bg-opacity-10 p-8 sm:items-start h-full rounded-md">
                            <div className="space-y-4 flex flex-col justify-between h-full">
                                <div className="text-lg leading-6 text-white font-medium space-y-1">
                                    <h3 className="font-condensed text-2xl uppercase">4) SOCIAL CONTENT</h3>
                                    <p className="text-base font-light text-gray">We will be putting out a fun and engaging segment where our content creators go around public places connecting with people about NFTs. Besides being entertaining, we hope this helps educate people about what NFTs can provide as well as showcase NFTs from artists and projects throughout the space. Teeth holders can potentially advertise in these segments.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <div className="bg-black-light bg-opacity-10 p-8 sm:items-start h-full rounded-md">
                            <div className="space-y-4 flex flex-col justify-between h-full">
                                <div className="text-lg leading-6 text-white font-medium space-y-1">
                                    <h3 className="font-condensed text-2xl uppercase">5) FAN FRIDAYS</h3>
                                    <p className="text-base text-gray">We want this space to be about supporting one another. Every week, as a community, we will purchase work from new and emerging artists in the space. The works will be collectively owned by holders as a part of our “Toy Chest.”</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <div className="bg-black-light bg-opacity-10 p-8 sm:items-start h-full rounded-md">
                            <div className="space-y-4 flex flex-col justify-between h-full">
                                <div className="text-lg leading-6 text-white font-medium space-y-1">
                                    <h3 className="font-condensed text-2xl uppercase">6) SPONSORING ATHLETES</h3>
                                    <p className="text-base text-gray">For the first time, college athletes can use their likeness to help support themselves financially. We want to team up with student athletes to not only help them achieve their dreams, but to be a part of our journey in tackling issues around the world.</p>
                                </div>
                            </div>
                        </div>
                    </div>  */}

                </div>

            </div>


            <img src={d23} alt="" ref={d23Ref} className="hidden lg:block shards opacity-0 absolute right-40 top-20 z-40" />
            <img src={d24} alt="" ref={d24Ref} className="hidden sm:block shards opacity-0 absolute -left-4 top-full z-40" />
            <img src={d25} alt="" ref={d25Ref} className="hidden sm:block shards opacity-0 absolute left-1/4 top-full z-40" />
            <img src={d26} alt="" ref={d26Ref} className="hidden sm:block shards opacity-0 absolute right-40 top-full z-40" />
        </div>
    )
}

export default Mission
