import React, { useEffect, useRef, useState } from "react"
// import { TwitterTweetEmbed } from 'react-twitter-embed';
import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

import d27 from "../images/diamonds/d27.png"
import d28 from "../images/diamonds/d28.png"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Twitter = (props) => {
    const d27Ref = useRef(null)
    const d28Ref = useRef(null)

    const [tweets, setTweets] = useState([])

    const getTweetId = (url) => {

        const id = url.replace('https://twitter.com/', '')
            .replace('https://www.twitter.com/', '')
            .split('?')[0]
            .split('/')[2]

        return id
    }

    useEffect(() => {
        setTweets(props.tweets.map((url) => getTweetId(url.path)))

        gsap.to(d27Ref.current, {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
                trigger: ".announcements",
                scrub: true,
            },
        })

        gsap.to(d28Ref.current, {
            yPercent: -45,
            ease: "none",
            scrollTrigger: {
                trigger: ".announcements",
                scrub: true,
            },
        })
    }, [props])

    return (
        <div className="announcements announcement_stars relative w-screen h-full">
            <div className="lg:max-w-7xl mx-4 sm:mx-6 xl:mx-auto pt-12 px-2 sm:pt-24 sm:px-0">
                <p className="mt-1  font-extrabold font-condensed tracking-wider text-white uppercase  text-4xl sm:text-5xl text-center">recent announcements</p>

                {tweets.length > 0 ?
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-6 z-20">
                        {/*
                            <div className="w-full">
                                <TwitterTweetEmbed
                                    className="shadow-2xl"
                                    tweetId={tweets[0]}
                                />
                            </div>

                            <div className="w-full">
                                <TwitterTweetEmbed
                                    className="shadow-2xl"
                                    tweetId={tweets[1]}
                                />
                            </div>

                            <div className="w-full">
                                <TwitterTweetEmbed
                                    className="shadow-2xl"
                                    tweetId={tweets[2]}
                                />
                            </div> */}
                    </div>
                    : null
                }

            </div>

            <img src={d27} alt="" ref={d27Ref} className="hidden sm:block shards opacity-0 absolute right-2/4 top-0 z-10" />
            <img src={d28} alt="" ref={d28Ref} className="hidden sm:block shards opacity-0 absolute right-20 top-3/2 z-10" />
        </div>
    )
}

export default Twitter
