import React, { useEffect, useRef, useState } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

import d15 from "../images/diamonds/d15.png"
import d16 from "../images/diamonds/d16.png"
import d17 from "../images/diamonds/d17.png"
import d18 from "../images/diamonds/d18.png"
import d19 from "../images/diamonds/d19.png"
import d20 from "../images/diamonds/d20.png"
import d21 from "../images/diamonds/d21.png"
import d22 from "../images/diamonds/d22.png"

import daria from "../images/teams/daria.png"
import tory from "../images/teams/tory.png"
import jots from "../images/teams/jots.png"
import placeholder from "../images/teams/placeholder.jpg"
import berkley from "../images/teams/berkley.jpg"
import faith from "../images/teams/faith.png"
import dann from "../images/teams/dann.png"
import slydunk from "../images/teams/slydunk.jpeg"
import drcape from "../images/teams/drcape.png"
import mark from "../images/teams/mark.png"
import cki from "../images/teams/cki.png"
import joanne from "../images/teams/joanne.png"
import paul from "../images/teams/paul.png"
import ella from "../images/teams/ella.png"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Team = (props) => {

    const d15Ref = useRef(null)
    const d16Ref = useRef(null)
    const d17Ref = useRef(null)
    const d18Ref = useRef(null)
    const d19Ref = useRef(null)
    const d20Ref = useRef(null)
    const d21Ref = useRef(null)
    const d22Ref = useRef(null)

    // const [team, setTeam] = useState([])

    useEffect(() => {
        // setTeam(props.team)

        gsap.to(d15Ref.current, {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
                trigger: ".team",
                scrub: true,
            },
        })

        gsap.to(d16Ref.current, {
            yPercent: -45,
            ease: "none",
            scrollTrigger: {
                trigger: ".team",
                scrub: true,
            },
        })

        gsap.to(d17Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".team",
                scrub: true,
            },
        })

        gsap.to(d18Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".team",
                scrub: true,
            },
        })

        gsap.to(d19Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".team",
                scrub: true,
            },
        })

        gsap.to(d20Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".team",
                scrub: true,
            },
        })

        gsap.to(d21Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".team",
                scrub: true,
            },
        })

        gsap.to(d22Ref.current, {
            yPercent: -55,
            ease: "none",
            scrollTrigger: {
                trigger: ".team",
                scrub: true,
            },
        })
    }, [props])

    return (
        <div className="team team_stars relative w-screen h-full bg-black">
            <div className="lg:max-w-7xl mx-4 sm:mx-6 xl:mx-auto py-12 px-2 sm:py-24 sm:px-0">
                <p className="mt-1 font-extrabold font-condensed tracking-wider text-white uppercase text-4xl sm:text-5xl text-center">our team</p>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-2 lg:gap-x-4 mt-12">

                    <div className="col-span-1 h-full">
                        <a href="https://twitter.com/daria_daria14" target="_blank" rel="noreferrer" >
                            <div className="team-member bg-black-light daria bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3 rounded-md">
                                    <img src={daria} alt="Daria" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Daria</h3>
                                            <p className="text-white text-base">Lead Artist</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray">Houdini FX artist exploring the boundaries of 3D. She makes digital sculptures using procedural geometry and shapes found in nature.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@daria_daria14</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-span-1 h-full">
                        <a href="https://twitter.com/dannpetty" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light dann bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={dann} alt="Dann" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium">
                                            <h3 className="font-condensed text-2xl uppercase">Dann</h3>
                                            <p className="text-white text-base">Creative Director</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray-light">20+ year freelance web designer + creative director for Fortune 100 companies.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@dannpetty</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    {/* <div className="col-span-1 h-full">
                        <a href="https://twitter.com/JotsColumns" target="_blank" rel="noreferrer" >
                            <div className="team-member bg-black-light jaw bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={jots} alt="jots column" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Jots</h3>
                                            <p className="text-white text-base">Correspondent</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray">Skilled writer with experience simplifying complex information.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@jotscolumns</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> */}

                    <div className="col-span-1 h-full">
                        <a href="https://twitter.com/Joannehollings" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light joanne bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={joanne} alt="e32" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Joanne</h3>
                                            <p className="text-white text-base">Photography Coordinator</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray">Passionate photographer and digital artist.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@joannehollings</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    {/* <div className="col-span-1 h-full">
                        <a href="https://twitter.com/whoisberkley" target="_blank" rel="noreferrer" >
                            <div className="team-member bg-black-light berkley bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={berkley} alt="berkley" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Berkley</h3>
                                            <p className="text-white text-base">Content Creator</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray">Co-Founder of The Sync.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@whoisberkley</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> */}

                    {/* <div className="col-span-1 h-full">
                        <a href="https://twitter.com/faithordway7" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light faith bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={faith} alt="faith" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Faith</h3>
                                            <p className="text-white text-base">Content Creator</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray">The clown at your next party.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@faithordway7</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> */}

                    {/* <div className="col-span-1 h-full">
                        <a href="https://twitter.com/envision32" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light salivary bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={placeholder} alt="Salivary Cyst" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Salivary Cyst</h3>
                                            <p className="text-white text-base">Developer</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray">OG NFT developer, former white hat hacker, and ETH maximalist coding from the stars.</p>
                                        </div>
                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@envision32</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> */}


                    <div className="col-span-1 h-full">
                        <a href="https://twitter.com/markbucknell" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light mark bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={mark} alt="mark" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Mark</h3>
                                            <p className="text-white text-base">Web Developer</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray">Freelance Web + Mobile App Developer.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@markbucknell</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    {/* <div className="col-span-1 h-full">
                        <a href="https://twitter.com/torybryant20" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light tory bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={tory} alt="Tory" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Tory</h3>
                                            <p className="text-white text-base">Artist</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray">
                                                Award-winning artist—animation, design, and fine arts. Within Nike’s space kitchen, toy giant Hasbro and Laika stop motion animation.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@torybryant20</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> */}

                    {/* <div className="col-span-1 h-full">
                        <a href="https://twitter.com/ckivang" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light cki bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={cki} alt="Dann" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium">
                                            <h3 className="font-condensed text-2xl uppercase">Cki</h3>
                                            <p className="text-white text-base">Artist</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray-light">20+ years experience in the video game industry as a 3D modeler and concept designer.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@ckivang</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> */}

                    {/* <div className="col-span-1 h-full">
                        <a href="https://twitter.com/Sen3Paul" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light paul bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={paul} alt="Paul" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium">
                                            <h3 className="font-condensed text-2xl uppercase">Paul</h3>
                                            <p className="text-white text-base">Artist</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray-light">3D artist drawn to the subtle intricacies and detail of biomechanical subjects, as well as photo realistic renderings.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@sen3paul</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> */}

                    <div className="col-span-1 h-full">
                        <a href="https://twitter.com/slydunk" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light slydunk bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={slydunk} alt="slydunk" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="text-lg leading-6 text-white font-medium">
                                            <h3 className="font-condensed text-2xl uppercase">Slydunk</h3>
                                            <p className="text-white text-base">Administrative Support</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray-light">Staying out of trouble behind the scenes.</p>
                                        </div>

                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@slydunk</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-span-1 h-full">
                        <a href="https://twitter.com/gabriellamort" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light ella bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={ella} alt="e32 logo" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Ella</h3>
                                            <p className="text-white text-base">Producer</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray-light">Bridging the gap between art and science to create a stronger narrative around sustainability.</p>
                                        </div>
                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>

                                        <span>@gabriellamort</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-span-1 h-full">
                        <a href="https://twitter.com/drcape" target="_blank" rel="noreferrer">
                            <div className="team-member bg-black-light drcape bg-opacity-10 p-3 grid grid-cols-1 sm:grid-cols-3 sm:items-start gap-4 sm:space-y-0 rounded-md h-full">
                                <div className="row-start-2 sm:row-start-1 aspect-w-3 aspect-h-3">
                                    <img src={drcape} alt="dr cape" className="rounded-md" />
                                </div>
                                <div className="sm:col-span-2 py-2 flex justify-between flex-col h-full z-10">
                                    <div className="">
                                        <div className="leading-6 text-white font-medium ">
                                            <h3 className="font-condensed text-2xl uppercase">Dr. Cape</h3>
                                            <p className="text-white text-base">Head of Operations</p>
                                        </div>
                                        <div className="text-base">
                                            <p className="text-gray-light">Not a doctor, yet.</p>
                                        </div>
                                    </div>
                                    <div className="text-white group flex flex-row gap-2 mt-4">
                                        <span className="sr-only">Twitter</span>
                                        <svg className="w-5 h-5 transform group-hover:scale-110" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                        <span>@drcape</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>


                </div>
            </div>

            <img src={d15} alt="" ref={d15Ref} className="hidden sm:block shards opacity-0 absolute -left-2 top-4 z-40" />
            <img src={d16} alt="" ref={d16Ref} className="hidden lg:block shards opacity-0 absolute left-40 top-12 z-40" />
            <img src={d17} alt="" ref={d17Ref} className="hidden lg:block shards opacity-0 absolute left-1/4 top-20 z-40" />
            <img src={d18} alt="" ref={d18Ref} className="hidden sm:block shards opacity-0 absolute right-1/4 top-0 z-40" />
            <img src={d19} alt="" ref={d19Ref} className="hidden sm:block shards opacity-0 absolute right-96 top-40 z-40" />
            <img src={d20} alt="" ref={d20Ref} className="hidden sm:block shards opacity-0 absolute -left-4 top-1/2 z-40" />
            <img src={d21} alt="" ref={d21Ref} className="hidden sm:block shards opacity-0 absolute right-0 top-1/2 z-40" />
            <img src={d22} alt="" ref={d22Ref} className="hidden sm:block shards opacity-0 absolute -right-2 top-full z-40" />
        </div>
    )
}

export default Team
