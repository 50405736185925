import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

import d13 from "../images/diamonds/d13.png"
import d14 from "../images/diamonds/d14.png"

import gif from "../images/e32-static.gif"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Cast = () => {

    const d13Ref = useRef(null)
    const d14Ref = useRef(null)

    useEffect(() => {
        gsap.to(d13Ref.current, {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
                trigger: ".collection",
                scrub: true,
            },
        })

        gsap.to(d14Ref.current, {
            yPercent: -80,
            ease: "none",
            scrollTrigger: {
                trigger: ".collection",
                scrub: true,
            },
        })
    }, [])

    return (
        <div className="collection collection_stars relative w-screen h-full bg-transparent">
            <div className="lg:max-w-7xl mx-4 sm:mx-6 xl:mx-auto py-16 px-2 sm:py-24 sm:px-0">
                <div className="row-span-2 col-span-4 text-center flex sm:hidden flex-col justify-evenly lg:px-16">
                    <h1 className="text-white text-4xl sm:text-5xl font-extrabold font-condensed tracking-wide uppercase">
                        <span className="block">E32 Cast</span>
                    </h1>
                    <p className="max-w-xl mx-auto text-base font-normal text-gray-light sm:text-gray mt-3">
                        This group of 32 characters will be the face of what Envision32 is trying to accomplish. 16 of them will be characters we will build around from existing projects and 16 will be original characters we create ourselves. The goal is to have each one be identifiable as a representative, or hero, for a real world issue.</p>
                    {/* <p className="max-w-xl mx-auto text-base font-normal text-gray-light sm:text-gray mt-3">
                        We will create a backstory piece for each character and they will come together as a team of 32. Our community will vote to decide where we fill the remainder of the 16 characters from. We are exploring ways to have all the characters held for this project belong to our tooth holders as a part of our "Toy Chest."</p> */}
                </div>
                <div className="grid grid-cols-4 sm:grid-cols-8 gap-4 auto-rows-max mt-8 sm:mt-0">
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/drcape.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="ape"
                        /> */}
                    </div>
                    {/* <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/cast/dog.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="dog"
                        />
                    </div> */}
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/guttercat.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="gutter cat"
                        /> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/metahero.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="metahero"
                        /> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/babykong.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="kong"
                        /> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/clone.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="clone x"
                        /> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/women.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="world of women"
                        /> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/deadfella.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="deadfella"
                        /> */}
                    </div>

                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/cat.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="cat"
                        /> */}
                    </div>


                    {/* <div className="bg-collection col-span-1">
                        <StaticImage
                            src="../images/cast/guttercat.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="gutter cat"
                        />
                    </div> */}
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="row-span-2 col-span-4 text-center hidden sm:flex flex-col justify-center space-y-4 lg:px-12">
                        <h1 className="text-white text-xl sm:text-3xl lg:text-5xl font-condensed tracking-wide uppercase">
                            <span className="hidden sm:block">E32 Cast</span>
                        </h1>
                        <p className="max-w-xl mx-auto text-sm font-normal text-gray-light sm:text-gray mt-3">
                            This group of 32 characters will be the face of what Envision32 is trying to accomplish. 16 of them will be characters we will build around from existing projects and 16 will be original characters we create ourselves. The goal is to have each one be identifiable as a representative, or hero, for a real world issue.</p>
                        {/* <p className="max-w-xl mx-auto text-sm font-normal text-gray-light sm:text-gray mt-3">
                            We will create a backstory piece for each character and they will come together as a team of 32. Our community will vote to decide where we fill the remainder of the 16 characters from. We are exploring ways to have all the characters held for this project belong to our tooth holders as a part of our "Toy Chest."</p> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/veefriend.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="veefriend"
                        /> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />

                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/character1.png"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="character 1"
                        /> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                        {/* <StaticImage
                            src="../images/cast/character2.jpg"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="character 2"
                        /> */}
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                    <div className="bg-collection col-span-1">
                        <img
                            src={gif}
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <img src={d13} alt="" ref={d13Ref} className="hidden lg:block shards opacity-0 absolute -left-4 -top-4 z-40" />
            <img src={d14} alt="" ref={d14Ref} className="hidden sm:block shards opacity-0 absolute right-4 -top-12 lg:-top-4 z-40" />
        </div>
    )
}

export default Cast
