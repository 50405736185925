import React, { useEffect, useRef } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "../gsap/ScrollTrigger.js"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const Cutout = () => {
    const addToRefs = useRef(null)
    const fullRef = useRef(null)

    useEffect(() => {
        let timelineReverse = gsap.timeline({
            paused: true,
            delay: 1,
            onComplete: function() {

                let timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".scrollPoint",
                        start: "top top",
                        scrub: 1,
                    }
                })

                // timeline.set(document.body, {overflow: "hidden", duration: 10})

                timeline.to('.Full_Tooth', {
                    duration: 0.1,
                    opacity: 0,
                },0);

                timeline.fromTo('.top', {
                    x: 0,
                    y: 0,
                }, {
                    duration: 15,
                    x: 20,
                    y: -1000,
                    stagger: 0.3,
                    ease: "expo.out",
                }, 0);

                timeline.fromTo('.right', {
                    x: 0,
                    y: 0,
                    opacity: 1,
                }, {
                  duration: 3,
                      x: 1000,
                      y: 10,
                      opacity: 0,
                      rotation: '15_cw',
                      stagger: 0.3,
                      ease: "expo.out",
                  }, 0);

                  timeline.fromTo('.left', {
                      x: 0,
                      y: 0,
                      opacity: 1,
                  }, {
                      duration: 3,
                      x: -1000,
                      y: 'random(50, 100)',
                      opacity: 0,
                    rotation: '315_ccw',
                    stagger: 0.3,
                    ease: "expo.out",
                }, 0);

                  timeline.fromTo('.bottomLeft', {
                      x: 0,
                      y: 0,
                      scale: 1,
                  }, {

                      duration: 3,
                      scale: 'random([2,2.1,2.2,2.3])',
                      filter:"blur(3px)",
                    x: -1500,
                    y: 'random(200, 400)',
                    rotation: '315_ccw',
                    stagger: 0.2,
                    ease: "expo.out",
                }, 0);

               timeline.fromTo('.bottomRight', {
                   x: 0,
                   y: 0,
                   scale: 1,
               }, {

                   duration: 3,
                   scale: 'random([2,2.1,2.2,2.3])',
                   filter:"blur(3px)",
                 x: 1500,
                 y: 'random(200, 400)',
                 rotation: '15_cw',
                 stagger: 0.2,
                 ease: "expo.out",
             }, 0);


               timeline.fromTo('.middleTopLeft', {
                   x: 0,
                   y: 0,
                   scale: 1,
                   opacity: 1
               }, {
                duration: 3,
                   scale: 4,
                   filter:"blur(2px)",
                   x: -150,
                   y: 'random(-20, -40)',
                //    opacity: 0,
                   rotation: '20_cw',
                   stagger: 0.8,
                   ease: "expo.out",
               }, 0);

               timeline.fromTo('.middleTopRight', {
                   x: 0,
                   y: 0,
                   scale: 1,
                   opacity: 1
               }, {
                duration: 3,
                   scale: 4,
                   filter:"blur(2px)",
                   x: 150,
                   y: 'random(-20, -40)',
                //    opacity: 0,
                   rotation: '315_ccw',
                   stagger: 0.8,
                   ease: "expo.out",
               }, 0);

               timeline.fromTo('.middleBottomLeft', {
                   x: 0,
                   y: 0,
                   scale: 1,
                   opacity: 1
               }, {
                duration: 3,
                   scale: 2,
                   filter:"blur(2px)",
                   x: 'random(-1600, -1700)',
                   y: 'random(1700, 1800)',
                //    opacity: 0,
                   rotation: '15_cw',
                   stagger: 0.08,
                   ease: "expo.out",
               }, 0);

                timeline.fromTo('.middleBottomRight', {
                    x: 0,
                    y: 0,
                    scale: 1,
                    opacity: 1
                }, {
                    duration: 3,
                    scale: 2,
                    filter:"blur(2px)",
                    x: 'random(1600, 1700)',
                    y: 'random(1700, 1800)',
                    // opacity: 0,
                    rotation: '315_cw',
                    stagger: 0.05,
                    ease: "expo.out",
                }, 0);




                timeline.fromTo('.shards',{
                    opacity: 0
                }, {
                      duration: 0.5,
                      opacity: 1,
                  }, 0);

                //   timeline.set(document.body, {overflow: "auto"})

            }
        })

        timelineReverse.to('.shards', {
            duration: 0.1,
            opacity: 0,
        });


        timelineReverse.fromTo('.right', {
            x: 1500,
            y: 75,

            opacity: 0
        }, {
            duration: 3,
            x: 0,
            y: 0,

            stagger: 0.03,
            opacity: 1,
        }, 0.5);

        timelineReverse.fromTo('.left', {
            x: -1500,
            y: 20,

            opacity: 0
        }, {
            duration: 3,
            x: 0,
            y: 0,
            stagger: 0.01,
            opacity: 1,
        }, 0.5);

        timelineReverse.fromTo('.top', {
            x: 90,
            y: -1000,
            opacity: 0,
        }, {
            duration: 3,
            x: 0,
            y: 0,
            stagger: 0.02,
            opacity: 1,
        }, 0.5);

        timelineReverse.fromTo('.bottom', {
            x: 30,
            y: 1000,
            opacity: 0,
        }, {
            duration: 2.5,
            x: 0,
            y: 0,
            stagger: 0.03,
            opacity: 1,

        }, 0.5);

        timelineReverse.fromTo('.middle', {
            x: -700,
            y: -600,
            z: 1,
          scale: 4,
          opacity: 1,
          filter:"blur(2px)",
        }, {
            duration: 3,
            x: 0,
            y: 0,
            stagger: 0.01,
            scale: 1,
            opacity: 1,
            filter: 'blur(0)'
        }, 0);

        timelineReverse.fromTo(fullRef.current, {
          opacity: 0
        }, {
            duration: 0.1,
            opacity: 1,
        }, 4);

        timelineReverse.play()
    }, [])

    return (
        <div className=" relative h-full w-full">
            <div ref={fullRef} className="opacity-0 Full_Tooth"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_238"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_239"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_240"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_241"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_242"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_243"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_244"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_245"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_246"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_247"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_248"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_249"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_250"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_251"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_252"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_253"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_254"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_255"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_256"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_257"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_258"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_259"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_260"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_261"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_262"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_263"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_264"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_265"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_266"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_267"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_268"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_269"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_270"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_271"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_272"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_273"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_274"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_275"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_276"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_277"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_278"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_279"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_280"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_281"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_282"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_283"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_284"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_285"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_286"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_287"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_288"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_289"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_290"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_291"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_292"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_293"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_294"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_295"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_296"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_297"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_298"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_299"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_300"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_301"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_302"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_303"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_304"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_305"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_306"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_307"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_308"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_309"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_310"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_311"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_312"></div>
            <div ref={addToRefs} className="diamond middle middleBottomRight opacity-0 Layer_313"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_314"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_315"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_316"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_317"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_318"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_319"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_320"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_321"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_322"></div>
            <div ref={addToRefs} className="diamond middle middleTopLeft opacity-0 Layer_323"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_324"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_325"></div>
            <div ref={addToRefs} className="diamond middle middleTopLeft opacity-0 Layer_326"></div>
            <div ref={addToRefs} className="diamond middle middleTopLeft opacity-0 Layer_327"></div>
            <div ref={addToRefs} className="diamond middle middleTopLeft opacity-0 Layer_328"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_329"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_330"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_331"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_332"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_333"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_334"></div>
            <div ref={addToRefs} className="diamond middle middleTopLeft opacity-0 Layer_335"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_336"></div>
            <div ref={addToRefs} className="diamond middle middleTopRight opacity-0 Layer_337"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_338"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_339"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_340"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_341"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_342"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_343"></div>
            <div ref={addToRefs} className="diamond middle middleTopLeft opacity-0 Layer_344"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_345"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_346"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_347"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_348"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_349"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_350"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_351"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_352"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_353"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_354"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_355"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_356"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_357"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_358"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_359"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_360"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_361"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_362"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_363"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_364"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_365"></div>
            <div ref={addToRefs} className="diamond middle middleBottomLeft opacity-0 Layer_366"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_367"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_368"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_369"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_370"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_371"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_372"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_373"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_374"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_375"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_376"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_377"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_378"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_379"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_380"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_381"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_382"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_383"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_384"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_385"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_386"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_387"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_388"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_389"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_390"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_391"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_392"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_393"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_394"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft inset-5opacity-0 Layer_395"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight inset-5opacity-0 Layer_396"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight inset-5opacity-0 Layer_397"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_398"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_399"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_400"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_401"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_402"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_403"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_404"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_405"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_406"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_407"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_408"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_409"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_410"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_411"></div>
            <div ref={addToRefs} className="diamond bottom bottomRight opacity-0 Layer_412"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_413"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_414"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_415"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_416"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_417"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_418"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_419"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_420"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_421"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_422"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_423"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_424"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_425"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_426"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_427"></div>
            <div ref={addToRefs} className="diamond bottom bottomLeft opacity-0 Layer_428"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_429"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_430"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_431"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_432"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_433"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_434"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_435"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_436"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_437"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_438"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_439"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_440"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_441"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_442"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_443"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_444"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_445"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_446"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_447"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_448"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_449"></div>
            <div ref={addToRefs} className="diamond middle middleTopLeft opacity-0 Layer_450"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_451"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_452"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_453"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_454"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_455"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_456"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_457"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_458"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_459"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_460"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_461"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_462"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_463"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_464"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_465"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_466"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_467"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_468"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_469"></div>
            <div ref={addToRefs} className="diamond left opacity-0 Layer_470"></div>
            <div ref={addToRefs} className="diamond top opacity-0 Layer_471"></div>
            <div ref={addToRefs} className="diamond right opacity-0 Layer_472"></div>
        </div>
    )
}

export default Cutout
